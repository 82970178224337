import { useSessionUser } from "lib/client/hooks/session-user";
import { signOut } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

type LogoImageProps = {
  width?: number;
  height?: number;
  style?: "default" | "purple" | "white";
};

export function Logo({
  center = false,
  forceLogout = false,
  clickable = true,
  ...props
}: {
  center?: boolean;
  forceLogout?: boolean;
  clickable?: boolean;
} & LogoImageProps) {
  const { push } = useRouter();
  const { session } = useSessionUser();

  const content = (
    <span className="h-8 flex items-center justify-center">
      <LogoImage {...props} />
    </span>
  );

  const onClickLogo = async () => {
    if (session) {
      await signOut({ redirect: false });
      push("/");
    }
  };

  return (
    <div className={`${center ? "mx-auto" : "w-full md:w-24"}`}>
      {clickable ? (
        forceLogout ? (
          <button type="button" onClick={onClickLogo}>
            {content}
          </button>
        ) : (
          <Link href="/" className="flex justify-center">
            {content}
          </Link>
        )
      ) : (
        <div>{content}</div>
      )}
    </div>
  );
}

export function LogoImage({ style = "default", width = 83, height = 28 }: LogoImageProps) {
  const logoSrc = style === "purple" ? "/logo-purple.svg" : style === "white" ? "/logo-white.svg" : "/logo.svg";

  return <Image src={logoSrc} width={width} height={height} priority alt="Vetted logo" />;
}
