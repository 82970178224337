import fastSafeStringify from "fast-safe-stringify";
import seedrandom from "seedrandom";

export const PRODUCTION_DOMAIN = "https://vettedhealth.com";

export const VETTED_CUSTOMER_ID = "vetted";
export const VETTED_PARTNER_CUSTOMER_ID = "vetted-health";

export function getEnv() {
  return process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
}

export function isProduction() {
  return getEnv() === "production";
}

export function isPreview() {
  return getEnv() === "preview";
}

export function isDevelopment() {
  return getEnv() === "development";
}

export function getAppDomain() {
  const domain = process.env.NEXT_PUBLIC_APP_BASE_URL ?? process.env.APP_BASE_URL ?? PRODUCTION_DOMAIN;
  return removeTrailingSlash(domain);
}

export function removeTrailingSlash(str: string) {
  return str.replace(/\/$/, "");
}

export function deferToNextEventLoop(callback: () => void): void {
  setTimeout(callback, 0);
}

export async function delay(timeout: number) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}

export function arraify(value?: string | string[]) {
  if (value === null || value === undefined || value === "") return undefined;
  if (!Array.isArray(value)) return [value];

  return value;
}

export function parseInteger(value: string | number) {
  if (typeof value === "number") return value;
  if (typeof value === "string") return parseInt(value, 10);

  return 0;
}

export function range(n: number) {
  return Array.from({ length: n }, (_, i) => i);
}

export function takeOne(array: string[] | string) {
  return Array.isArray(array) ? array[0] : array;
}

export function deleteUndefined(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
}

export function deleteUndefinedOrNull(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === null) {
      delete obj[key];
    }
  });
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomIntWithSeed(seed: string, min: number, max: number) {
  const rng = seedrandom(seed);
  return Math.floor(rng() * (max - min + 1)) + min;
}

export function getRandomArrayItem<T>(array: T[]) {
  return array[getRandomInt(0, array.length - 1)];
}

export function getStableRandomId(seed: string, max: number) {
  return getRandomIntWithSeed(seed, 1, max);
}

export function getDoubleDigitsStableRandomId(seed: string, max: number) {
  return String(getStableRandomId(seed, max)).padStart(2, "0");
}

export function csvStringToArray(csvString?: string) {
  return csvString ? csvString.split(",").map((item) => item.trim()) : [];
}

export function csvStringToNumberArray(csvString?: string): number[] {
  return csvString ? csvString.split(",").map(Number) : [];
}

export function arrayToCSV(array: (string | number | boolean)[]): string {
  return array.join(",");
}

export function deduplicateArray<T>(array: T[]) {
  return Array.from(new Set(array)) as T[];
}

export function toTitleCase(str: string) {
  return str
    .trim()
    .replace(/\s+/g, " ")
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toLocaleUpperCase() + txt.substring(1).toLocaleLowerCase());
}

export function isObjectEmpty(obj: any) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function encodePlusSignInEmailAddress(email: string) {
  return email.replace(/\+/g, "%2B");
}

export function getEnumKeyByValue<T extends Record<string, string>>(myEnum: T, enumValue: string) {
  return Object.keys(myEnum)[Object.values(myEnum).indexOf(enumValue)];
}

export function createFormDataFromObject<T extends { append(name: string, value: string): void }>(
  formData: T,
  object: Record<string, any>
) {
  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value as string);
  });
  return formData;
}

export function getEmailDomain(email: string | undefined | null) {
  if (!email) return null;

  return email.split("@")[1];
}

export function safeStringify(obj: any) {
  return fastSafeStringify(obj);
}

export function mergeArraysWithoutDuplicates<T>(...arrays: T[][]) {
  return Array.from(new Set(arrays.flat()));
}

export function slugifyId(id: string) {
  return id
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");
}

export function safeDateParse(dateString: string): Date | undefined {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return undefined;
  }
  return date;
}

export function getMMDDYYYYFormattedDate(date: string | Date) {
  const currentDate = new Date(date);
  const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  return formattedDate;
}

export function getThresholdDate(threshold: number): Date {
  const thresholdDate = new Date();
  thresholdDate.setDate(thresholdDate.getDate() - threshold);
  return thresholdDate;
}
