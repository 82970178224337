import { ClinicianAvailableForNextJob, ClinicianPayExpectation, JobApplicationType } from "@prisma/client";
import { arraify, parseInteger, safeDateParse, takeOne } from "lib/utils";
import { ParsedUrlQuery } from "querystring";
import { JobsQueryRequest } from "./client/api/job";
import { ClinicianListQueryRequest } from "./client/api/recruiter/clinicians";
import { ConversationsQueryRequest } from "./client/api/recruiter/conversations";
import { ClinicianDetailsLastWorkedEnum } from "./types";

export type RequestQuery = Partial<{
  [key: string]: string | string[];
}>;

export function getJobFiltersFromQuery(query: RequestQuery): JobsQueryRequest {
  return {
    specialties: arraify(query.specialties)?.map(parseInteger),
    states: arraify(query.states),
    minPay: query.minPay ? parseInteger(takeOne(query.minPay)) : undefined,
    maxPay: query.maxPay ? parseInteger(takeOne(query.maxPay)) : undefined,
    employers: arraify(query.employers),
    startDate: arraify(query.startDate),
    jobDuration: arraify(query.jobDuration),
    locationCategoryId: query.locationCategoryId ? parseInteger(takeOne(query.locationCategoryId)) : undefined,
    isNewJob: query.isNewJob ? JSON.parse(takeOne(query.isNewJob)) : undefined,
    isHighestPay: query.isHighestPay ? JSON.parse(takeOne(query.isHighestPay)) : undefined,
    jobsPerPage: query.jobsPerPage ? parseInteger(takeOne(query.jobsPerPage)) : undefined,
    whereToTravel: arraify(query.whereToTravel),
  };
}

export function getClinicianFiltersFromQuery(query: RequestQuery): ClinicianListQueryRequest {
  let applicationType = arraify(query.applicationType);
  if (applicationType) {
    applicationType = mapApplicationTypesFromQuery(applicationType);
  }

  const availableForNextJob = arraify(query.availableForNextJob);
  if (availableForNextJob) {
    availableForNextJob.forEach((item) => {
      if (!Object.keys(ClinicianAvailableForNextJob).includes(item)) {
        throw new Error("Invalid ClinicianAvailableForNextJob filter");
      }
    });
  }

  const lastWorked = arraify(query.lastWorked);
  if (lastWorked) {
    lastWorked.forEach((item) => {
      if (!Object.keys(ClinicianDetailsLastWorkedEnum).includes(item)) {
        throw new Error("Invalid ClinicianDetailsLastWorkedEnum filter");
      }
    });
  }

  const payExpectation = arraify(query.payExpectation);
  if (payExpectation) {
    payExpectation.forEach((item) => {
      if (!Object.keys(ClinicianPayExpectation).includes(item)) {
        throw new Error("Invalid ClinicianPayExpectation filter");
      }
    });
  }

  let hasMessaged = arraify(query.hasMessaged);
  if (hasMessaged?.includes("true") && hasMessaged?.includes("false")) {
    hasMessaged = undefined;
  }

  return {
    customer: query.customer as string,
    isLead: query.isLead ? JSON.parse(takeOne(query.isLead)) : undefined,
    applicationType: applicationType as JobApplicationType[],
    minDateSent: query.minDateSent ? safeDateParse(takeOne(query.minDateSent)) : undefined,
    maxDateSent: query.maxDateSent ? safeDateParse(takeOne(query.maxDateSent)) : undefined,
    lastActiveAt: query.lastActiveAt ? safeDateParse(takeOne(query.lastActiveAt)) : undefined,

    firstName: query.firstName as string,
    lastName: query.lastName as string,
    specialtyIds: arraify(query.specialtyIds)?.map(parseInteger),
    leadScore: arraify(query.leadScore),
    hasMessaged: hasMessaged,
    minCompletionPercentage: query.minCompletionPercentage
      ? parseInteger(takeOne(query.minCompletionPercentage))
      : undefined,

    licensedStatesIds: arraify(query.licensedStatesIds),
    preferredStatesIds: arraify(query.preferredStatesIds),
    yearsOfExperience: query.yearsOfExperience ? parseInteger(takeOne(query.yearsOfExperience)) : undefined,
    hasTraveled: query.hasTraveled ? JSON.parse(takeOne(query.hasTraveled)) : undefined,
    openToTravel: query.openToTravel ? JSON.parse(takeOne(query.openToTravel)) : undefined,

    availableForNextJob: availableForNextJob,
    availableDate: query.availableDate ? safeDateParse(takeOne(query.availableDate)) : undefined,
    lastWorked: lastWorked,
    payExpectation: payExpectation,

    status: arraify(query.status),
    recruiterIds: arraify(query.recruiterIds)?.map(parseInteger),
    feedbackTypeIds: arraify(query.feedbackTypeIds)?.map(parseInteger),

    sortColumn: query.sortColumn as string,
    sortOrder: query.sortOrder as string,
  };
}

export function getConversationFiltersFromQuery(query: RequestQuery): ConversationsQueryRequest {
  let applicationType = arraify(query.applicationType);
  if (applicationType) {
    applicationType = mapApplicationTypesFromQuery(applicationType);
  }

  return {
    customer: query.customer as string,

    firstName: query.firstName as string,
    lastName: query.lastName as string,
    minCompletionPercentage: query.minCompletionPercentage
      ? parseInteger(takeOne(query.minCompletionPercentage))
      : undefined,
    maxCompletionPercentage: query.maxCompletionPercentage
      ? parseInteger(takeOne(query.maxCompletionPercentage))
      : undefined,
    specialtyIds: arraify(query.specialtyIds)?.map(parseInteger),
    applicationType: arraify(query.applicationType),
    leadScore: arraify(query.leadScore),

    recruiterIds: arraify(query.recruiterIds)?.map(parseInteger),
    feedbackTypeIds: arraify(query.feedbackTypeIds)?.map(parseInteger),
    status: arraify(query.status),
    lastMessageStartDate: query.lastMessageStartDate ? safeDateParse(takeOne(query.lastMessageStartDate)) : undefined,
    lastMessageEndDate: query.lastMessageEndDate ? safeDateParse(takeOne(query.lastMessageEndDate)) : undefined,
  };
}

function mapApplicationTypesFromQuery(applicationTypes: string[]) {
  applicationTypes.forEach((item) => {
    if (!Object.keys(JobApplicationType).includes(item)) {
      throw new Error("Invalid JobApplicationType filter");
    }
    if (item === JobApplicationType.MANUAL_APPLICATION) {
      applicationTypes.push(JobApplicationType.SUPER_APPLY);
    }
    if (item === JobApplicationType.AUTO_PROPOSAL) {
      applicationTypes.push(JobApplicationType.BULK_APPLY);
    }
  });

  return applicationTypes as JobApplicationType[];
}

export function queryObjectToQueryString(queryObject: any): string {
  return Object.entries(queryObject)
    .filter(([_key, value]) => (Array.isArray(value) ? value.length : value !== undefined && value !== null))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");
}

export function getPageFromQuery(query: RequestQuery): number {
  return parseInteger(takeOne(query.page || "0"));
}

export function getUtmParams(query: ParsedUrlQuery) {
  return Object.entries(query)
    .filter(([key]) => key.startsWith("utm_"))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
}

export function parseQueryParam(array: string[] | string | null | undefined) {
  return (Array.isArray(array) ? array[0] : array) ?? null;
}

export function parseQueryParamReplaceSpaces(array: string[] | string | null | undefined) {
  const parsed = parseQueryParam(array);
  return parsed?.replace(/\+/g, " ") ?? null;
}

export async function fetchAllPages<T>(
  query: (pagination: { skip: number; take: number }) => Promise<T[]>,
  pageSize: number,
  logInterval = 0
): Promise<T[]> {
  let currentPage = 1;
  let fetchedData: T[] = [];
  let hasMore = true;

  while (hasMore) {
    const data = await query({
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
    });
    fetchedData = fetchedData.concat(data);

    if (logInterval !== 0 && fetchedData.length % logInterval === 0) {
      console.log(`Fetched page ${currentPage}, current total records fetched: ${fetchedData.length}`);
    }

    if (data.length < pageSize) {
      hasMore = false;
    } else {
      currentPage++;
    }
  }

  return fetchedData;
}
