import { UserType } from "@prisma/client";
import { createIntercomHash } from "lib/client/api/backoffice/intercom";
import { useSessionUser } from "lib/client/hooks/session-user";
import { useEffect } from "react";

function InitIntercom() {
  const { userData, isLoading } = useSessionUser();

  useEffect(() => {
    if (isLoading) return;
    if (userData) {
      const { name, email, customerId, recruiterId, userType, isRecruiterActive, isCustomerActive, id, customerName } =
        userData;

      const loadIntercom = async () => {
        const { userHash } = await createIntercomHash(id);
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID!,
          name: name,
          email: email,
          customer_id: customerId,
          user_id: id,
          user_type: userType,
          is_recruiter_active: isRecruiterActive,
          created_at: Math.floor(Date.now() / 1000),
          user_hash: userHash,
          recruiter_id: recruiterId,
        };

        const w = window;
        const ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", window.intercomSettings);
        } else {
          const i = function (...args: any) {
            i.c(args);
          };
          i.q = [] as any[];
          i.c = function (args: any) {
            i.q.push(args);
          };
          w.Intercom = i;

          const l = function () {
            const s = document.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${window.intercomSettings.app_id}`;
            const x = document.getElementsByTagName("script")[0];
            x?.parentNode?.insertBefore(s, x);
          };

          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      };
      if (userType !== UserType.CLINICIAN) loadIntercom();
    }
    return () => {
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
    };
  }, [userData]);

  return null;
}

export default InitIntercom;
