import { useEffect, useState } from "react";

export default function usePageLoadedOrInteractive() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    function handleReadyStateChange() {
      if (document.readyState === "complete") {
        setLoaded(true);
      }
    }

    handleReadyStateChange();

    document.addEventListener("readystatechange", handleReadyStateChange);
    return () => {
      document.removeEventListener("readystatechange", handleReadyStateChange);
    };
  }, []);

  return loaded;
}
