import Image from "next/legacy/image";
import Link from "next/link";
import { UrlObject } from "url";
import { Spinner } from "./spinner";

type LinkButtonProps = {
  href: string | UrlObject;
  label: string;
  bigText?: boolean;
  height?: string;
  className?: string;
};

type ButtonProps = {
  type?: "button" | "submit";
  onClick: () => void;
  icon?: React.ReactNode;
  label: string;
  title?: string;
  className?: string;
  height?: string;
  width?: string;
  textColor?: string;
  bgColor?: string;
  disabledBgColor?: string;
  bigText?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  spinnerWidth?: string;
  spinnerHeight?: string;
  spinnerSpacing?: string;
};

type FancyButtonProps = {
  ctaText: string;
  subText?: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
};

export function LinkButton({ href, label, bigText = false, height = "h-12", className = "" }: LinkButtonProps) {
  return (
    <Link
      href={href}
      className={`bg-primary w-full rounded-full text-white flex items-center justify-center font-medium ${
        bigText ? "text-xl" : "text-base"
      } ${height} ${className}`}
    >
      {label}
    </Link>
  );
}

export function Button({
  type = "button",
  onClick,
  icon,
  label,
  title,
  className = "",
  height = "h-12",
  textColor = "text-white",
  bgColor = "bg-primary",
  disabledBgColor = "bg-silver",
  bigText = false,
  isLoading = false,
  disabled = false,
  spinnerWidth = "w-6",
  spinnerHeight = "h-6",
  spinnerSpacing = "mr-3",
  width = "w-full",
}: ButtonProps) {
  let basicStyles = "rounded-full flex items-center font-medium";
  if (!className.includes("justify")) {
    basicStyles += " justify-center";
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${basicStyles} ${bigText ? "text-xl" : "text-base"} ${height} ${width} ${
        disabled ? disabledBgColor : bgColor
      } ${textColor} ${className}`}
      title={title}
      disabled={disabled || isLoading}
    >
      <div className={`-ml-1 ${spinnerWidth} ${spinnerHeight} ${spinnerSpacing} ${isLoading ? "block" : "hidden"}`}>
        <Spinner />
      </div>
      {icon && <div className={`${bigText ? "h-6 w-6" : "h-5 w-5"} mr-2`}>{icon}</div>}
      {label}
    </button>
  );
}

export function BackButton({ href, onClick }: { href?: string | UrlObject; onClick?: () => void }) {
  const className = "w-24 h-10 bg-white rounded-full flex items-center justify-center gap-2 font-medium shadow";
  const content = (
    <>
      <Image src="/images/icons/back-curved.svg" width={16} height={16} />
      Back
    </>
  );
  return href ? (
    <Link href={href} className={className}>
      {content}
    </Link>
  ) : (
    <button onClick={onClick} className={className}>
      {content}
    </button>
  );
}

export function FancyButton({ ctaText, subText, onClick, disabled, isLoading = false, children }: FancyButtonProps) {
  return (
    <button
      className={`relative flex items-center text-white h-14 w-full rounded-full shadow ${
        disabled ? "bg-silver" : "bg-primary"
      }`}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {children}
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className={`w-6 h-6 mr-3 ${isLoading ? "block" : "hidden"}`}>
          <Spinner />
        </div>
        <div className={`${isLoading ? "hidden" : "block"}`}>
          <div className="text-xl font-bold leading-5 tracking-tight">{ctaText}</div>
          {subText && <div className="italic text-white/60 leading-4">{subText}</div>}
        </div>
      </div>
    </button>
  );
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      bgColor={props.bgColor ?? "bg-white"}
      textColor="text-primary"
      className="border border-primary"
    />
  );
}

export function ActionButton(props: ButtonProps) {
  return (
    <button
      {...props}
      className={`${props.className} bg-stone-100 hover:bg-stone-200 rounded px-2.5 py-[7px] hover:shadow-sm active:shadow relative`}
    >
      {props.children}
      <img src="/images/icons/filter.svg" className="w-[20px]" />
    </button>
  );
}

export function UnderlineButton({
  onClick,
  label,
  className = "",
  textColor = "text-black",
  isLoading = false,
  disabled = false,
}: ButtonProps) {
  let basicStyles = "underline font-medium";
  if (!className.includes("justify")) {
    basicStyles += " justify-center";
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${basicStyles} ${textColor} ${className}`}
      disabled={disabled || isLoading}
    >
      {isLoading && <Spinner />}
      {label}
    </button>
  );
}
