import axios from "axios";
import { ClinicianConversation } from "./client/api/conversations";
import { NotificationPreferencesResponse } from "./types";

const getBrowserName = (): string => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Chrome") && !userAgent.includes("Edge") && !userAgent.includes("OPR")) {
    return "Chrome";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  } else if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("Edge")) {
    return "Edge";
  } else if (userAgent.includes("OPR") || userAgent.includes("Opera")) {
    return "Opera";
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
    return "Internet Explorer";
  } else {
    return "Unknown";
  }
};

const fetchNotificationPreferences = async (
  userId: string,
  browserName: string
): Promise<NotificationPreferencesResponse> => {
  try {
    const response = await axios.get(`/api/notification-preference`, {
      params: { userId, browserName },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching notification preferences:", error);
    throw error;
  }
};

const updateNotificationPreference = async (
  userId: string,
  userType: string,
  browserName: string,
  isEnabled: boolean
): Promise<void> => {
  try {
    await axios.post("/api/notification-preference", {
      userId,
      userType,
      browserName,
      desktopNotificationsEnabled: isEnabled,
    });
  } catch (error) {
    console.error("Error updating notification preference:", error);
    throw error;
  }
};

export const handleNotificationPermissions = async (userId: string, userType: string): Promise<void> => {
  const browserName = getBrowserName();
  const currentPreferences = await fetchNotificationPreferences(userId, browserName);

  if (!("Notification" in window)) {
    console.log("This browser does not support system notifications!");
    return;
  }

  if (Notification.permission === "granted") {
    if (!currentPreferences.id || currentPreferences.desktopNotificationsEnabled !== true) {
      await updateNotificationPreference(userId, userType, browserName, true);
    }
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission(async (permission) => {
      const isEnabled = permission === "granted";
      if (currentPreferences.desktopNotificationsEnabled !== isEnabled) {
        await updateNotificationPreference(userId, userType, browserName, isEnabled);
      }
    });
  } else {
    if (currentPreferences.desktopNotificationsEnabled !== false) {
      await updateNotificationPreference(userId, userType, browserName, false);
    }
  }
};

export const sendNotification = (user: string, message: string, onClick: () => void): void => {
  const body = getNotificationBody(user, message);
  const notification = new Notification("New message from Vetted", {
    icon: "/logo-purple.svg",
    body,
  });
  notification.onclick = () => onClick();
};

const getNotificationBody = (user: string, message?: string): string => {
  return message ? `${user} says: ${message}` : `${user} sent you a message`;
};

export const checkAndSendNotification = (
  isAuthenticated: boolean,
  mostRecentConversation: ClinicianConversation | undefined,
  notificationSent: boolean,
  redirectToConversation: () => void
): boolean => {
  if (isAuthenticated && mostRecentConversation && !notificationSent) {
    const user = mostRecentConversation?.recruiter?.firstName || "";
    const message = mostRecentConversation?.content || "";
    sendNotification(user, message, redirectToConversation);
    return true;
  }
  return notificationSent;
};
