export type Cookies =
  | Partial<{
      [key: string]: string;
    }>
  | string;

export function getCookie(name: string, cookies: Cookies) {
  if (typeof cookies === "string") {
    try {
      return cookies
        .split("; ")
        .find((row) => row.startsWith(`${name}=`))
        ?.split("=")[1];
    } catch (e) {
      return undefined;
    }
  } else {
    return cookies[name];
  }
}

export function getFbpCookie(cookies: Cookies) {
  return getCookie("_fbp", cookies);
}
