import { AnalyticsBrowser } from "@segment/analytics-next";
import {
  enrichTrackingProperties,
  normalizeUserTraitsForTracking,
  UserAction,
  UserPropsForTracking,
} from "lib/analytics-utils";
import usePageLoadedOrInteractive from "lib/client/hooks/page-loaded";
import { useSessionUser } from "lib/client/hooks/session-user";
import { isProduction } from "lib/utils";
import router from "next/router";
import { createContext, useContext, useEffect, useMemo } from "react";

export type AnalyticsClient = {
  track: (event: UserAction, properties: UserPropsForTracking) => Promise<any>;
  identify: (userId: string) => Promise<any>;
  page: () => Promise<any>;
};

export const AnalyticsContext = createContext<AnalyticsClient | undefined>(undefined);

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export function trackEventForFacebookPixel(
  analytics: AnalyticsClient | undefined,
  userAction: UserAction,
  userId: string,
  userData: UserPropsForTracking
) {
  if (!analytics) return;
  if (userData.userType !== "CLINICIAN") return;

  const { identify, track } = analytics;
  identify(userId);
  const { userType, email, firstName, lastName, phone } = userData;
  track(userAction, { userType, email, firstName, lastName, phone });
}

export default function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  const isPageLoaded = usePageLoadedOrInteractive();

  const analytics = useMemo(() => {
    if (!isPageLoaded) return undefined;

    let browser: { track: any; identify: any; page: any };
    if (isProduction()) {
      const writeKey = process.env.NEXT_PUBLIC_CLIENT_ANALYTICS_WRITE_KEY;
      if (!writeKey) throw new Error("Analytics write key not set");

      browser = AnalyticsBrowser.load({ writeKey });
    } else {
      browser = {
        track: console.info.bind(null, "track"),
        identify: console.info.bind(null, "identify"),
        page: console.info.bind(null, "page"),
      };
    }

    return {
      track: (event: UserAction, props: UserPropsForTracking) =>
        browser.track(event, enrichTrackingProperties(document.cookie, normalizeUserTraitsForTracking(props))),
      identify: (userId: string) => browser.identify(userId),
      page: () => browser.page(enrichTrackingProperties(document.cookie)),
    } as AnalyticsClient;
  }, [isPageLoaded]);

  const { userData, isLoading: isLoadingSession } = useSessionUser();

  useEffect(() => {
    if (!analytics || isLoadingSession) return;

    const userId = userData?.id;
    if (userId) analytics.identify(userId);
  }, [analytics, isLoadingSession]);

  useEffect(() => {
    if (!analytics) return;

    analytics.page();

    const handleRouteChange = () => analytics.page();
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [analytics]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}
