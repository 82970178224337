import { UserType } from "@prisma/client";
import { Cookies, getCookie } from "./cookies";
import { toTitleCase } from "./utils";

// read this before adding stuff here: https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
export enum UserAction {
  CLINICIAN_SIGNUP_STARTED = "Clinician Signup Started",
  CLINICIAN_SIGNUP_COMPLETED = "Clinician Signup Completed",
  CLINICIAN_QUALIFIED = "Clinician Qualified",
  JOB_APPLICATION_SUBMITTED = "Job Application Submitted",
  JOB_SEARCHED = "Job Searched",
  JOB_VIEWED = "Job Viewed",

  // there's no way to add mappings for these in the Segment UI, need to use the target event name directly
  FB_PIXEL_CLINICIAN_SIGNUP_STARTED = "Lead",
  FB_PIXEL_CLINICIAN_SIGNUP_COMPLETED = "Complete Registration",
  FB_PIXEL_JOB_APPLICATION_SUBMITTED = "Submit Application",
  FB_PIXEL_JOB_SEARCHED = "Search",
  FB_PIXEL_JOB_VIEWED = "View Content",
}

export type UserPropsForTracking = {
  userType: UserType;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
};

export function enrichTrackingProperties(cookies: Cookies, properties?: any) {
  const fbc = getCookie("_fbc", cookies);
  const fbp = getCookie("_fbp", cookies);

  return { ...properties, actionSource: "website", fbc, fbp };
}

export function normalizeUserTraitsForTracking(props: UserPropsForTracking) {
  const { email, firstName, lastName, phone } = props;
  return {
    email: email.toLocaleLowerCase(),
    firstName: toTitleCase(firstName || ""),
    lastName: toTitleCase(lastName || ""),
    phone: (phone || "").replace(/[^\d]/g, ""),
  };
}
