import axios from "axios";
import { handleNotificationPermissions } from "lib/notifications-utils";
import { parseQueryParamReplaceSpaces } from "lib/query-helper";
import { ClinicianData } from "lib/types";
import { isDevelopment, isPreview } from "lib/utils";
import { signIn } from "next-auth/react";
import { ParsedUrlQuery } from "querystring";
import { Url, UrlObject } from "url";
import {
  NewClinicianPostResponse,
  NewClinicianPutResponse,
  continueSignup,
  postNewClinician,
} from "./clinician/signup";

export type VerifyUserExistenceResponse = {
  exists: boolean;
  postLoginUrl?: string;
};

export type UserDataInSession = {
  id: string;
  name: string;
  email: string;
  emailVerified: boolean;
  userType: "ADMIN" | "CLINICIAN" | "RECRUITER";
  signupCompleted: boolean;
  firstName?: string;
  lastName?: string;
  phone?: string;
  isInternal?: boolean;
  customerId: string;
  recruiterId: string;
  image?: string;
  isRecruiterActive?: boolean;
  isCustomerActive?: boolean;
  customerName?: string;
};

type CreateAndUpdateClinicianResponse = {
  resNewClinician: NewClinicianPostResponse;
  resUpdatedClinician: NewClinicianPutResponse;
};

export const LOGIN_REDIRECT_QUERY_PARAM = "check-email-message";
export const PROVIDER_EMAIL = "email";
export const PROVIDER_NEW_CLINICIAN_LOGIN = "new-clinician-login";
export const PROVIDER_MAGIC_LINK = "magic-link-login";

export function buildSignupQuery(params: ParsedUrlQuery, jobId: string | undefined | null) {
  const query = { ...params, job: jobId, specialty: params.specialty };

  if (!query.job) delete query.job;
  if (!query.specialty) {
    delete query.specialty;
  } else {
    query.specialty = parseQueryParamReplaceSpaces(query.specialty) ?? undefined;
  }
  return query;
}

const createAndUpdateClinician = async ({
  data,
  email,
  referralData,
}: {
  data: Partial<ClinicianData>;
  email: string;
  referralData?: any;
}): Promise<CreateAndUpdateClinicianResponse> => {
  const resNewClinician = await postNewClinician(email, referralData);
  const resUpdatedClinician = await continueSignup(data, undefined, referralData);
  return { resNewClinician, resUpdatedClinician };
};

const redirectUrlIfUserExists = ({ jobId, referralData }: { jobId?: string | null; referralData?: any }) => {
  let callbackUrl = "";
  if (jobId) {
    callbackUrl = `/signup-success/?utm_content=${referralData.utm_content}&job=${jobId}`;
  } else {
    callbackUrl = `/signup-success/?utm_content=${referralData.utm_content}`;
  }

  return callbackUrl;
};

const redirectIfUserDoesNotExist = async ({
  specialty,
  jobId,
  referralData,
  email,
  onRedirect,
}: {
  resUpdatedClinician: NewClinicianPutResponse;
  specialty?: string;
  jobId?: string | null;
  referralData?: any;
  email: string;
  onRedirect: (path: UrlObject | string) => void;
}) => {
  if (jobId) {
    await axios.post(`/api/jobs/${jobId}/application?preSignupSuccess=true`);
    onRedirect({
      pathname: "/signup-success",
      query: buildSignupQuery({ ...referralData, email, specialty, job: jobId }, jobId),
    });
  } else {
    onRedirect({ pathname: "/signup-success", query: buildSignupQuery(referralData, undefined) });
  }
};

const redirectToUploadResume = ({
  referralData,
  email,
  specialty,
  jobId = null,
  onRedirect,
}: {
  referralData?: any;
  email: string;
  specialty?: string;
  jobId?: string | null;
  onRedirect: (path: UrlObject | string) => void;
}) => {
  const url: UrlObject = {
    pathname: "/signup",
    query: buildSignupQuery({ ...referralData, step: "resume", email, specialty }, jobId),
  };
  onRedirect(url);
  return;
};

export async function signupExperiment202309({
  data,
  jobId,
  referralData,
  specialty,
  onRedirect,
}: {
  data: Partial<ClinicianData>;
  jobId?: string | null;
  referralData?: any;
  specialty?: string;
  onRedirect: (path: UrlObject | string) => void;
}) {
  const { email } = data;
  if (!email) throw new Error("Invalid params");
  const { resNewClinician, resUpdatedClinician } = await createAndUpdateClinician({ data, email, referralData });

  if (resNewClinician.status === "login") {
    const callbackUrl = redirectUrlIfUserExists({ jobId, referralData });
    onRedirect(await loginWithEmail(email, callbackUrl));
  } else {
    const loginResponse = await loginNewClinician(email, resUpdatedClinician.otp);
    if (loginResponse?.status === 200) {
      await signIn(PROVIDER_EMAIL, { email, redirect: false, callbackUrl: "/" });
      redirectIfUserDoesNotExist({ resUpdatedClinician, specialty, jobId, referralData, email, onRedirect });
    } else {
      throw new Error("Unexpected error finishing signup: failed login with otp");
    }
  }
}

const createRedirectionResumeUrl = ({
  email,
  jobId = null,
  referralData,
}: {
  email: string;
  jobId?: string | null;
  referralData?: any;
}) => {
  let callbackUrl = "";
  if (jobId) {
    callbackUrl = `/signup?utm_content=${referralData.utm_content}&job=${jobId}&step=resume&email=${email}`;
  } else {
    callbackUrl = `/signup?utm_content=${referralData.utm_content}&step=resume&email=${email}`;
  }
  return callbackUrl;
};

export async function signupExperiment202310({
  data,
  jobId = null,
  referralData,
  specialty,
  isFinalStep,
  onRedirect,
}: {
  data: Partial<ClinicianData>;
  jobId?: string | null;
  referralData?: any;
  specialty?: string;
  isFinalStep?: boolean;
  onRedirect: (path: UrlObject | string) => void;
}) {
  const { email } = data;
  if (!email) throw new Error("Invalid params");

  if (isFinalStep) {
    const callbackUrl = redirectUrlIfUserExists({ jobId, referralData });
    onRedirect(callbackUrl);
    return;
  }

  const { resNewClinician, resUpdatedClinician } = await createAndUpdateClinician({ data, email, referralData });
  if (isPreview() || isDevelopment()) {
    if (!resUpdatedClinician.resumeUrl) {
      if (resNewClinician.status === "login") {
        const callbackUrl = createRedirectionResumeUrl({ email, jobId, referralData });
        onRedirect(await loginWithEmail(email, callbackUrl));
        return;
      }

      await loginNewClinician(email, resUpdatedClinician.otp);
      if (jobId) {
        await axios.post(`/api/jobs/${jobId}/application?preSignupSuccess=true`);
      }
      redirectToUploadResume({ referralData, email, specialty, jobId, onRedirect });
      return;
    } else {
      const callbackUrl = redirectUrlIfUserExists({ jobId, referralData });
      onRedirect(await loginWithEmail(email, callbackUrl));
      return;
    }
  }

  if (resNewClinician.status === "login") {
    const callbackUrl = redirectUrlIfUserExists({ jobId, referralData });
    onRedirect(await loginWithEmail(email, callbackUrl));
  } else {
    await loginNewClinician(email, resUpdatedClinician.otp);
    redirectIfUserDoesNotExist({ resUpdatedClinician, specialty, jobId, referralData, email, onRedirect });
  }
}

export async function beginSignup({
  email,
  jobId,
  specialty,
  referralData,
  onRedirect,
}: {
  email: string;
  jobId?: string;
  specialty?: string;
  referralData?: any;
  onRedirect: (path: UrlObject | string) => void;
}) {
  if (!email) throw new Error("Invalid params");
  const response = await postNewClinician(email, referralData);
  const { status } = response;
  if (status === "available" || status === "resume-signup") {
    const url: UrlObject = {
      pathname: "/signup",
      query: buildSignupQuery({ ...referralData, step: "profile", email, specialty }, jobId),
    };
    onRedirect(url);
  } else if (status === "login") {
    const callbackUrl = jobId ? `/job/${jobId}` : undefined;
    onRedirect(await loginWithEmail(email, callbackUrl));
  } else {
    throw new Error("Unexpected response status");
  }
}

export async function loginWithEmail(email: string, callbackUrl = "/"): Promise<Url> {
  const response = await signIn(PROVIDER_EMAIL, { email, callbackUrl, redirect: false });
  if (response?.status === 200) {
    return { pathname: "/", query: { [LOGIN_REDIRECT_QUERY_PARAM]: "true" } } as unknown as Url;
  } else {
    throw new Error("Unexpected error signing in");
  }
}

export async function loginNewClinician(email: string, otp?: string) {
  return await signIn(PROVIDER_NEW_CLINICIAN_LOGIN, { email, otp, redirect: false });
}

export async function verifyUserExistence(email: string) {
  return await axios.get<VerifyUserExistenceResponse>("/api/auth/verify-user-existence", { params: { email } });
}

export function getSessionData(session: any) {
  const { status } = session;
  const isAuthenticated = status === "authenticated";
  const isUnauthenticated = status === "unauthenticated";
  const isLoading = status === "loading";

  if (isAuthenticated && session?.data?.user) {
    const userData = session.data.user as UserDataInSession;
    handleNotificationPermissions(userData.id, userData.userType);
    return { isAuthenticated, isUnauthenticated, isLoading, userData, session };
  }

  return { isAuthenticated, isUnauthenticated, isLoading, userData: null, session };
}
