import axios, { AxiosResponse } from "axios";
import { ClinicianData, DefaultErrorResponse } from "lib/types";

export type NewClinicianSignupStep = "email" | "profile" | "details" | "finished" | "resume" | "unqualified";

export type NewClinicianPostBody = Partial<ClinicianData> & {
  referralData: any;
};

export type NewClinicianPutBody = {
  payload: Partial<ClinicianData>;
  referralData: any;
};

export type NewClinicianPutParams = {
  step: NewClinicianSignupStep;
};

export type NewClinicianGetResponse =
  | {
      status: "ok";
      userId: string;
      clinicianId: number;
      data: ClinicianData;
      profileCompletion: NewClinicianSignupStep;
    }
  | (DefaultErrorResponse & { redirectToSignupSuccess?: true });

export type NewClinicianPostResponse =
  | { status: "login" }
  | { status: "resume-signup"; data: ClinicianData; profileCompletion: NewClinicianSignupStep }
  | {
      status: "available";
      profileCompletion: NewClinicianSignupStep;
    }
  | (DefaultErrorResponse & {
      suggestion?: string;
    });

export type NewClinicianPutResponse = NewClinicianGetResponse & { otp?: string; resumeUrl?: string | null };

export async function postNewClinician(
  email: string,
  referralData: any | undefined
): Promise<NewClinicianPostResponse> {
  const { data } = await axios.post<NewClinicianPostBody, AxiosResponse<NewClinicianPostResponse>>(
    "/api/clinicians/new",
    {
      email,
      referralData,
    }
  );
  return data;
}

export async function continueSignup(
  payload: Partial<ClinicianData>,
  step?: NewClinicianSignupStep,
  referralData?: any | undefined
): Promise<NewClinicianPutResponse> {
  const { data } = await axios.put<NewClinicianPutBody, AxiosResponse<NewClinicianPutResponse>>(
    "/api/clinicians/new",
    {
      payload,
      referralData,
    },
    { params: { step } }
  );
  return data;
}

export async function fetchPendingSignupData(email: string): Promise<NewClinicianGetResponse> {
  const { data } = await axios.get<NewClinicianPutBody, AxiosResponse<NewClinicianGetResponse>>("/api/clinicians/new", {
    params: { email },
  });
  return data;
}
