import { UserType } from "@prisma/client";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { UserDataInSession, getSessionData } from "../api/auth";

type Session = {
  isAuthenticated: boolean;
  isUnauthenticated: boolean;
  isLoading: boolean;
  userData: UserDataInSession | null;
  session: any;
};

export function useSessionUser(): Session {
  return getSessionData(useSession());
}

export function useSessionUserCached() {
  const [sessionUserData, setSessionUserData] = useState<Session>({
    isAuthenticated: false,
    isUnauthenticated: false,
    isLoading: true,
    userData: null,
    session: null,
  });

  const data = getSessionData(useSession());
  useEffect(() => {
    if (!sessionUserData.isLoading) return;

    if (!data.isLoading) {
      setSessionUserData(data);
    }
  }, [data]);

  return sessionUserData;
}

export function useCheckRedirect() {
  const router = useRouter();
  const sessionUserData = useSessionUser();
  const { userData } = sessionUserData;

  useEffect(() => {
    const { isReady, pathname, push } = router;
    if (!isReady) return;

    //Check CLINICIAN sign up incomplete
    if (userData && userData.userType === UserType.CLINICIAN && !userData.signupCompleted) {
      if (pathname === "/") {
        push({ pathname: "/signup", query: { email: userData.email, step: "profile" } });
      }
    }

    //Check if should redirect to backoffice
    if (userData && userData.userType !== UserType.CLINICIAN && !pathname.includes("backoffice")) {
      push({ pathname: "/backoffice" });
    }
  }, [router, userData]);

  return sessionUserData;
}

export function useIsClinicianWithFinishedSignup() {
  const { isLoading, isAuthenticated, userData } = useSessionUser();
  if (isLoading) return undefined;

  return isAuthenticated && userData && userData.userType === "CLINICIAN" && userData.signupCompleted;
}

export function useIsRecruiter() {
  const { isLoading, isAuthenticated, userData } = useSessionUser();
  if (isLoading) return undefined;

  return isAuthenticated && userData && userData.userType === "RECRUITER";
}
