import axios from "axios";
import { signOut } from "next-auth/react";
import qs from "qs";
import { BareFetcher } from "swr";

type UnexpectedErrorCallback = ((error: any) => void) | null;
let onUnexpectedError: UnexpectedErrorCallback = null;

export const swrFetcher: BareFetcher<any> = (url, query, config = {}) =>
  axios
    .get(url, {
      params: query,
      paramsSerializer: (params) => qs.stringify(params, { indices: false }),
      ...config,
    })
    .then((res) => res.data);

export function setupAxios() {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      let throwError = error;
      if (error.response?.status === 401 && error.response?.data?.forceLogout) {
        await signOut();
        window.location.href = "/";
        return Promise.resolve();
      }
      if (error.response?.data?.error) {
        throwError = buildError(`API error ${error.response.status}`, error);
      }

      if (error.response?.status === 500) {
        if (onUnexpectedError) onUnexpectedError(throwError);
      }

      console.error(throwError);
      return Promise.reject(throwError);
    }
  );
}

export function configureAxiosUnexpectedError(onError: UnexpectedErrorCallback) {
  onUnexpectedError = onError;
}

function buildError(
  message: string,
  error: { name: any; message: any; stack: any; response: { data: { error: any } } }
) {
  const newError = new Error(`${message}: ${error.response.data.error}`);
  newError.cause = error;
  newError.stack = error.stack;
  return newError;
}
