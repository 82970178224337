import { useEffect } from "react";

export function useViewportHeigthListener() {
  useEffect(() => {
    window.addEventListener("resize", defineViewportVariables(document.documentElement));
  }, []);
}

/** This handles an iOS quirk. See https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
function defineViewportVariables(doc: HTMLElement) {
  let prevClientHeight: number;
  let prevClientWidth: number;

  function handleResize() {
    const { clientHeight, clientWidth } = doc;

    if (clientHeight !== prevClientHeight) {
      requestAnimationFrame(function updateViewportHeight() {
        doc.style.setProperty("--vh", clientHeight * 0.01 + "px");
        prevClientHeight = clientHeight;
      });
    }
    if (clientWidth !== prevClientWidth) {
      requestAnimationFrame(function updateViewportWidth() {
        doc.style.setProperty("--vw", clientWidth * 0.01 + "px");
        prevClientWidth = clientWidth;
      });
    }
  }

  handleResize();

  return handleResize;
}
