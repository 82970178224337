import { Button } from "components/common/button";
import { LogoImage } from "components/common/logo";
import Image from "next/legacy/image";

export default function Maintenance() {
  return (
    <div className="flex items-center flex-col gap-8 mt-6 mx-6 sm:mx-0">
      <LogoImage />

      <div className="w-full mx-4 md:w-[36rem] md:shrink-0 flex flex-col gap-6">
        <div className="bg-white flex flex-col justify-center px-4 py-5 sm:px-8 sm:py-10 rounded-2xl shadow">
          <Image src="/images/clipart/404.svg" width={380} height={230} />
          <div className="sm:px-20 mt-8">
            <h2 className="text-3xl font-bold text-center mb-4">
              We're currently under maintenance. Please try again later.
            </h2>
            <div className="text-center mt-8">
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                label="Try again"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
