import * as Sentry from "@sentry/nextjs";
import AnalyticsProvider from "components/common/app/analytics-provider";
import { Footer } from "components/common/layout/footer";
import { swrFetcher } from "lib/client/api/utils/configure-axios";
import { useAxios } from "lib/client/hooks/axios";
import { useSessionUser } from "lib/client/hooks/session-user";
import { useViewportHeigthListener } from "lib/client/hooks/viewport-height-listener";
import InitIntercom from "lib/client/integration/intercom";
import { isProduction } from "lib/utils";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import { useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { SWRConfig } from "swr";
import Maintenance from "./maintenance";
import "/styles/csv-reader-input.css";
import "/styles/globals.css";
import "/styles/image-slider.css";
import "/styles/react-widgets-custom.css";
import "/styles/time-to-scale.css";

function SentryProvider() {
  const { userData, isLoading } = useSessionUser();

  useEffect(() => {
    if (isLoading) return;
    if (userData) {
      const { email, id, customerId, recruiterId, userType } = userData;
      Sentry.setUser({
        email,
        id,
        RecruiterID: recruiterId,
        CustomerID: customerId,
        UserType: userType,
      });
    }
  }, [userData, isLoading]);

  return null;
}

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{
  session: Session;
}>) {
  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true") return <Maintenance />;

  useAxios();
  useViewportHeigthListener();

  return (
    <SessionProvider session={session}>
      <AnalyticsProvider>
        <SWRConfig
          value={{
            fetcher: swrFetcher,
            // revalidateIfStale: false,
            // revalidateOnFocus: false,
            // focusThrottleInterval: 50000,
          }}
        >
          <Component {...pageProps} />
          <Footer />
        </SWRConfig>
        <SentryProvider />
        {isProduction() && <InitIntercom />}
      </AnalyticsProvider>
    </SessionProvider>
  );
}
