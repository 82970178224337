import {
  Clinician,
  ClinicianAvailableForNextJob,
  ClinicianType,
  Conversation,
  ImportedJob,
  Job,
  JobApplication,
  JobApplicationType,
  LeadUploadStatus,
  Prisma,
  Recruiter,
  SpecialtyCategory,
  User,
} from "@prisma/client";
import { TargetStatus } from "lib/server/campaign-helper";

type Equal<S, T> = [S, T] extends [T, S] ? S : never;

export function allValuesCheck<T>(): <A extends T[]>(arr: Equal<A, T[]>) => T[] {
  return (arr) => arr;
}

export type NonEmptyArray<T> = [T, ...T[]];

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type DefaultBulkOperationResponse = {
  succesful: number;
};

export type DefaultSuccessResponse = {
  status: "ok";
};

export type DefaultErrorResponse = {
  status?: "error";
  error: string;
};

export enum ClinicianDetailsLastWorkedEnum {
  "CURRENTLY_WORKING" = "Currently working",
  "WORKED_1_TO_3_MONTHS_AGO" = "1-3 months ago",
  "WORKED_3_TO_8_MONTHS_AGO" = "3-8 months ago",
  "WORKED_8_PLUS_MONTHS_AGO" = "8+ months ago",
}

export enum WhyTravelEnum {
  LIFESTYLE = "Lifestyle",
  MONEY = "Money",
  "BE_CLOSE_TO_FAMILY" = "Be close to family",
}

export enum WhereToTravelEnum {
  "SPECIFIC_STATE" = "Specific state",
  "HOME_STATE" = "Home state",
  "NEIGHBORING_STATES" = "Neighboring states",
  WEST = "West",
  NORTHEAST = "Northeast",
  MIDWEST = "Midwest",
  SOUTH = "South",
}

export enum ClinicianPreferencesAvailableForNextJobEnum {
  ASAP = "ASAP",
  "FROM_3_TO_6_WEEKS" = "3-6 weeks",
  "FROM_2_TO_3_MONTHS" = "2-3 months",
  "FROM_3_PLUS_MONTHS" = "3+ months",
}

export enum ClinicianPreferencesPayExpectationEnum {
  "FROM_2K_TO_3K_PER_WEEK" = "2-3k per week",
  "FROM_3K_TO_4K_PER_WEEK" = "3-4k per week",
  "FROM_4K_TO_5K_PER_WEEK" = "4-5k per week",
  "FROM_5K_PLUS_PER_WEEK" = "5k+ per week",
}

export enum ClinicianShiftPreferences {
  "DAY" = "Day",
  "EVENING" = "Evening",
  "NIGHT" = "Night",
}

export type ClinicianData = Pick<
  Clinician,
  | "firstName"
  | "lastName"
  | "phone"
  | "yearsOfExperience"
  | "lastWorked"
  | "openToTravel"
  | "traveledBefore"
  | "availableForNextJob"
  | "payExpectation"
  | "wantsIntroduction"
  | "yearsOfTravelExperience"
  | "dateOfBirth"
  | "last4SSN"
  | "hasChargeExperience"
  | "availableDate"
> & {
  email: string;
  relatedSpecialties: number[] | [];
  licensedStates: string[] | [];
  preferredStates: string[] | [];
  chartingExperience: number[] | [];
  shiftPreferences: string[] | [];
  whyTravel?: string | null;
  whereToTravel?: string[] | [];
  maxPay?: number | null;
  minPay?: number | null;
};

export type SanitizedClinicianData = {
  data: ClinicianData;
};

export type LocationWithStateData = {
  city: string;
  state: StateData;
};

export type StateData = {
  id: string;
  name: string;
};

export type SpecialtyData = {
  id: number;
  name: string;
  specialty?: { category: SpecialtyCategory };
};

export type SpecialtyWithCategoryData = {
  id: number;
  name: string;
  category: string;
};

export type RelatedSpecialtyData = SpecialtyData;

export type RelatedSpecialtyWithSpecialtyData = RelatedSpecialtyData & {
  specialty: SpecialtyData;
};

export type ChartingExperienceData = {
  id: string;
  name: string;
};

export type AssignedRecruiterData = {
  id: number;
  customerId: string;
  recruiter: (Recruiter & { user: User }) | null | undefined;
} | null;

export type LeadData = {
  specialty: string;
  secondarySpecialties: string[];
  allSpecialtyIds: number[];
  allCategories: SpecialtyCategory[];
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  yearsExperience: number;
  traveledBefore: boolean | null;
  lastWorked: ClinicianDetailsLastWorkedEnum;
  startDate: ClinicianPreferencesAvailableForNextJobEnum;
  availableForNextJob: ClinicianAvailableForNextJob;
  homeState: string;
  preferredStates: string[];
  homeStateId: string;
  preferredStateIds: string[];
  openToTravelForContracts: boolean | null;
  payExpectation: ClinicianPreferencesPayExpectationEnum | null;
  externalJobId?: string;
  jobDescription?: string;
  job?: Partial<Job> & { importedJob: Partial<ImportedJob> | null };
  resumeUrl?: string;
  resumeFileName?: string;
  yearsOfTravelExperience?: number | null;
  hasChargeExperience?: boolean | null;
  availableDate?: Date | null;
  shiftPreferences?: string[];
  chartingExperience?: string[];
  type?: ClinicianType;
};

export type FormattedLeadData = {
  Specialty: string;
  "Secondary Specialties": string;
  Name: string;
  Email: string;
  Cell: string;
  "Years Experience": number;
  "Traveled Before": "Yes" | "No";
  "Last worked": ClinicianDetailsLastWorkedEnum;
  "Start Date": ClinicianPreferencesAvailableForNextJobEnum;
  "Home State": string;
  "Preferred States": string;
  "Open to travel for contracts?": "Yes" | "No";
  "Application Job ID"?: string;
  "Application Job"?: string;
  type?: ClinicianType;
};

export type CustomerDataForLeadUploader = {
  id: string;
  leadUploadTarget: string;
  leadConfig: Prisma.JsonValue;
  isJobless?: boolean;
};

export type FormattedJobData = {
  payRate: string;
  startDate: string;
  hoursPerWeek: number;
  shiftsPerWeek: number;
  hoursPerShift: number;
  shift: string;
  contractLengthInWeeks: number;
  location: string;
  specialtyName: string;
  wages: string;
  stipend: string;
  customerJobId?: string;
};

export type FormattedJobApplicationData = {
  customerId: string;
  leadStatus: LeadUploadStatus;
  uploadedAt: string;
  job: FormattedJobData;
  type: JobApplicationType;
};

export type LeadForwardingConfig = {
  categories?: NonEmptyArray<SpecialtyCategory>;
  specialties?: number[];
};

export type RecruiterLeadPreferences = {
  specialties?: number[];
};

export type ConversationWithJobApplication = Conversation & {
  jobApplication: JobApplication;
};

export type PresignedUrlData = {
  url: string;
  fields: any;
  fullPathToFile: string;
};

export type CertificationsType = {
  id: string;
  name: string;
};

export type CertificationData = {
  id: number;
  type?: CertificationsType;
  fileUrl?: string;
};

export type ReferenceData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  facilityName: string;
  facilityStateId: string | null;
  employmentStartDate: Date | null;
  employmentEndDate: Date | null;
  isCurrentEmployment: boolean;
};

export type LicenseData = {
  licenseTypeId: number | null;
  licenseNumber: string;
  licenseStateId: string | null;
  isCompact: boolean | null;
  notRequired: boolean;
};

export type ProfileBasicInfo = Pick<
  Clinician,
  | "firstName"
  | "lastName"
  | "phone"
  | "yearsOfExperience"
  | "openToTravel"
  | "availableForNextJob"
  | "payExpectation"
  | "wantsIntroduction"
  | "yearsOfTravelExperience"
  | "dateOfBirth"
  | "hasChargeExperience"
  | "availableDate"
  | "traveledBefore"
  | "whyTravel"
  | "whereToTravel"
  | "lastWorked"
  | "id"
> & {
  email: string | null;
  chartingExperience: string[] | [];
  shiftPreferences: string[] | [];
  licensedStates: string[] | [];
  preferredStates: string[] | [];
  relatedSpecialties: string[] | [];
};

export type ProfileLicenseInfo = {
  licenseNumber: string | null;
  licenseType: string | null;
  licenseState: string | null;
  isCompact: boolean | null;
  notRequired: boolean | null;
};

export type ProfileReferenceInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  facilityName: string;
  facilityState: string;
  employmentStartDate: Date;
  employmentEndDate: Date | null;
  isCurrentEmployment: boolean;
};

export type ProfileDocumentsInfo = {
  resumeUrl: string | undefined;
  resumeFileName: string | undefined;
  certifications: ProfileCertificationInfo[];
};

export type ProfileCertificationInfo = {
  type: string;
  fileUrl: string;
  fileName: string | undefined;
};

export type CategoriesResponse = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
};

export enum LocationCategories {
  "explore" = 0,
  "urban" = 1,
  "country" = 2,
  "new" = 3,
  "costOfLiving" = 4,
  "highestPay" = 5,
  "west" = 6,
  "northEast" = 7,
  "midWest" = 8,
  "south" = 9,
  "surfing" = 10,
  "mountains" = 11,
  "beach" = 12,
  "lakes" = 13,
  "tropical" = 14,
  "desert" = 15,
}

export type CampaignsData = {
  campaignsWithRecentlyTargetedLeads: SpecialtyBoostingType[];
};

export type CampaignTargetedCliniciansType = {
  id: number;
  campaignId: number;
  clinicianId: number;
  createdAt: string;
  updatedAt: string;
  status: TargetStatus;
};

export type SpecialtyBoostingType = {
  campaign: {
    id: number;
    channel: string;
    states: string[];
    specialties: number[];
    dailyTargetGoal: number;
    createdAt: string;
    updatedAt: string;
    active: boolean;
    customerId: string;
    availableTargets: number;
  };
  recentlyTargetedLeads: CampaignTargetedCliniciansType[];
};

export type TabConfigType = Record<string, JSX.Element>;

export type JobsTypesDictionary = {
  [key: string]: { title: string; desc: string };
};

export const jobsTypesData = {
  MANUAL_APPLICATION: {
    title: "Direct Apply",
    desc: "Clinician directly applied to one of your jobs",
  },
  LEAD_ONLY: {
    title: "General Lead",
    desc: "Clinician passed our 2 basic qualification checks: years of experience and willing to start a contract within the next 3 months",
  },
  AUTO_PROPOSAL: {
    title: "Auto Proposal",
    desc: "Vetted reached out to the clinician asking if they’re interested in assignments, and the clinician said yes",
  },
  AGENT_INTRO: {
    title: "Vetted Intro",
    desc: "Lead had a conversation with Vetted, and the clinician expressed interest in one of your jobs that was recommended",
  },
  BULK_APPLY: {
    title: "Bulk Apply",
    desc: "Clinician multi selected several jobs to apply to, including one of yours. Ensure you start this as an open ended conversation instead of referencing the specific job the clinician applied to",
  },
  SUPER_APPLY: {
    title: "Bulk Apply",
    desc: "Clinician multi selected several jobs to apply to, including one of yours. Ensure you start this as an open ended conversation instead of referencing the specific job the clinician applied to",
  },
  MANUAL_PROPOSAL: {
    title: "Manual Proposal",
    desc: "A clinician manually prospected using the database product.",
  },
};

export interface NotificationPreferenceRequest {
  userId: string;
  userType: string;
  browserName: string;
  desktopNotificationsEnabled: boolean;
}

export interface NotificationPreferencesResponse {
  id?: string;
  desktopNotificationsEnabled?: boolean;
}
